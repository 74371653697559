import '../App.css';
import '../index.css';
import React from 'react';
import HeaderAndFooter from './header-footer';
// import ParticlesBackground from './particleBackground';
import ScrollToComponent from './scrollToComponent';

function Home() {

    return (
        <div>
            {/* <ParticlesBackground color="#3eb39d" /> */}

            <HeaderAndFooter.Header classNames="w3-button w3-black w3-hover-teal nav-button"></HeaderAndFooter.Header>

            <img src="images/BVAPresentation.JPG" alt="Not loaded at the moment" />
            <div className="w3-display-topmiddle w3-center" style={{ paddingTop: "100px" }}>
                <h3 className="w3-center w3-padding-32"><span className="w3-tag w3-wide w3-round-large w3-text-teal">Phillip Donnelly</span></h3>
                {/* <button className="w3-btn w3-block w3-black w3-text-teal w3-round-large w3-animate-top big-button"
                    style={{ fontSize: "30px" }}>Phillip Donnelly</button> */}
            </div>

            <ScrollToComponent id="myBookScroll1">
                <div className="w3-container" id="about">
                    <div className="w3-content" style={{ maxWidth: "800px" }}>
                        <i className="fa-regular fa-dungeon"></i>
                        <p>
                            I made this website in the hopes of highlighting some projects and accomplishments I am proud of. Please feel free to reach out with any questions or comments. My contact info can be found in the footer. I hope you enjoy!
                        </p>
                    </div>
                </div>

                {/* <div className="w3-container" id="resume" style={{ paddingBottom: 50 }}>
                    <div className="w3-content">
                        <i className="fa-regular fa-dungeon"></i>
                        <h3 className="w3-center w3-padding-64"><span className="w3-tag w3-wide w3-round-large">Resume</span></h3>
                        <iframe title="resume" src="images/Resume.pdf" style={{ width: "100%", height: 1000 }}></iframe>
                    </div>
                </div> */}
            </ScrollToComponent>

            <HeaderAndFooter.Footer></HeaderAndFooter.Footer>

        </div>
    );
}

export default Home;